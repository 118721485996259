@import '../../../assets/style/variables';
@import '../../../assets/style/animations';

.road_map {
	@media only screen and (max-width: 767.98px) {
		top: 80vh;
	}

	position: relative;
	width: 100%;
	height: 100vh;
	top: 200px;
	display: flex;
	justify-content: center;
	max-height: 1080px;
	max-width: 2100px;
}
.cards {
	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		align-items: center;
		top: 40%;
	}
	position: relative;
	top: 10%;
	display: flex;
	justify-content: space-between;
	width: 80%;
}
.title {
	@media only screen and (max-width: 767.98px) {
		z-index: 999;
		top: -35%;
		left: 50%;
		transform: translateX(-50%);
	}

	color: $light-violet;
	position: absolute;
	font-size: 6em;
	top: 0;
	left: 0;
}
.subtitle {
	@media only screen and (max-width: 767.98px) {
		z-index: 999;
		top: -20%;
		width: 100%;
		text-align: center;
		left: 50%;
		transform: translateX(-50%);
	}
	position: absolute;
	top: 12%;
	color: white;
	font-size: 2.3em;
	left: 0;
}
