// Variables for easy customization
$fade-duration: 1s;
$fade-ease: ease-out;

// Base fade-in animation
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

// Fade in from the left
.fade-in-left {
	animation: fadeInLeft $fade-duration $fade-ease forwards;
	opacity: 0; // Initial state

	@keyframes fadeInLeft {
		from {
			opacity: 0;
			transform: translateX(-8%);
		}
		to {
			opacity: 1;
			transform: translateX(0);
		}
	}
}

// Fade in from the right
.fade-in-right {
	animation: fadeInRight $fade-duration $fade-ease forwards;
	opacity: 0; // Initial state

	@keyframes fadeInRight {
		from {
			opacity: 0;
			transform: translateX(8%);
		}
		to {
			opacity: 1;
			transform: translateX(0);
		}
	}
}

// Fade in from the top
.fade-in-top {
	animation: fadeInTop $fade-duration $fade-ease forwards;
	opacity: 0; // Initial state

	@keyframes fadeInTop {
		from {
			opacity: 0;
			transform: translateY(-20px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

// Fade in from the bottom
.fade-in-bottom {
	animation: fadeInBottom $fade-duration $fade-ease forwards;
	opacity: 0; // Initial state

	@keyframes fadeInBottom {
		from {
			opacity: 0;
			transform: translateY(20px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

// Optional: Add transition-delay if you want to delay the start of the animation
.fade-delay {
	animation-delay: 0.5s;
}
