@import '../../../assets/style/variables.scss';
@import '../../../assets/style/animations.scss';

.square {
	@media only screen and (max-width: 767.98px) {
		width: 90%;
		font-size: 80%;
		.number{
			top: -30px !important;
			left: -25px !important;
			width: 60px !important;
			height: 60px !important;
			font-size: 2.5em !important;
		}
	}

	position: relative;
	padding: 20px;
	color: white;
	opacity: 0;
	width: 400px;
	height: 400px;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	border-bottom-left-radius: 50px;
	border: 5px solid black;
	h1 {
		font-size: 3.5em;
		-webkit-text-stroke: 2px black;
	}
	p {
		font-size: 2.6em;
		line-height: normal;
		-webkit-text-stroke: 1.6px black;
	}
	.number {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 25%;
		width: 25%;
		font-size: 4em;
		left: -40px;
		top: -60px;
		padding: 20px;
		padding-bottom: 10px;
		border: 3px solid black;
		border-radius: 50%;
	}
}
.yellow {
	background-color: $yellow;
	box-shadow: 10px 10px 0 $dark-yellow;
	z-index: 6;
	.number {
		background-color: $yellow;
	}
}
.green {
	@media only screen and (max-width: 767.98px) {
		top: 0;
		&::after{
			display: none;
		}
		&::before{
			display: none;
		}
	}

	position: relative;
	top: 20%;
	background-color: $light-green;
	box-shadow: 10px 10px 0 $dark-green;
	z-index: 5;
	.number {
		background-color: $light-green;
	}
	&::after {
		content: '';
		position: absolute;
		right: 73%;
		top: 0;
		height: 70%;
		background-image: url(../../../assets/pictures/left-line.svg);
		z-index: -5;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	&::before {
		content: '';
		position: absolute;
		left: 76%;
		top: 0;
		height: 75%;
		background-image: url(../../../assets/pictures/right-line.svg);
		z-index: -5;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
}
.violet {
	background-color: $light-violet;
	z-index: 6;
	box-shadow: 10px 10px 0 $dark-violet;
	.number {
		background-color: $light-violet;
	}
}
