@import '../../../assets/style/variables';

.how_to_buy {
	@media only screen and (max-width: 767.98px) {
		top: 15vh;
		.title{
			font-size: 3rem !important;
			left: 50% !important;
			top: -20% !important;
			transform: translateX(-50%) !important;
		}
		.dashed_title{
			top: -25% !important;
			right: 5% !important;
			font-size:1.5em !important;
			padding: 5px 20px 0px 20px !important;
			-webkit-text-stroke: 2px transparent !important;
			left: auto !important;
		}
	}

	position: relative;
	top: 250px;
	height: 100vh;
	width: 100%;
	max-height: 1080px;
	max-width: 2100px;

	.title {
		position: absolute;
		top: -5% !important;
		left: 50%;
		transform: translateX(-50%) !important;
		color: $light-violet;
		font-size: 4.5em;
	}
	.dashed_title {
		position: absolute;
		top: -2%;
		left: 31%;
		color: $white;
		transform: rotate(7deg);
		background-color: $dark-violet;
		width: fit-content;
		border-radius: 50px;
		line-height: normal;
		font-size: 3.5em;
		padding: 10px 20px 0px 20px;
		border: 3px dashed $light-violet;
		-webkit-text-stroke: 2px black;
	}
}
.cards {
	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		gap: 10%;
		align-items: center;
		top: 0vh;
	}

	position: relative;
	top: 8vh;
	display: flex;
	align-items: baseline;
	justify-content: space-evenly;
	height: 95vh;
}
