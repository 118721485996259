$white: #fff;
$light-violet: #b562fb;
$violet: #6e399b;
$dark-violet: #4d286d;
$light-green: #0cd177;
$green: #0cd177;
$dark-green: #125537;
$yellow: #f8e317;
$dark-yellow: #736a16;
$font-weight: 700;
