@import '../../assets/style/variables.scss';

.footer {
	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		align-items: center;
		height: 60vh !important;
		bottom: -190vh;
		.picture{
			display: none;
		}
	}
	position: absolute;
	padding: 2% 10%;
	display: flex;
	justify-content: space-between;
	left: 0;
	height: 35vh;
	overflow: hidden;
	border-top-left-radius: 100px;
	border-top-right-radius: 100px;
	bottom: -80vh;
	box-shadow: 0px 0px 0px 20px $dark-violet;
	border: 3px solid black;
	background-color: #6e399b;
	width: 100%;
}
.info {

	@media only screen and (max-width: 767.98px) {
		max-width: 100%;
		text-align: center;
		align-items: center;
		gap: 10px;
	}
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: space-between;
	height: 100%;
	max-width: 30%;
	h3 {
		color: white;
		font-size: 2em;
		line-height: normal;
		margin-bottom: 40px;
		-webkit-text-stroke: 2px black;
	}
}
.picture {
	position: relative;
	width: 30%;
	height: inherit;
	img {
		height: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -60%);
	}
}
.contacts {
	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-top: 10%;
	}
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	h2 {
		@media only screen and (max-width: 767.98px) {
			width: 100vw !important;
		}
		color: white;
		font-size: 3.3em;

		line-height: normal;
		-webkit-text-stroke: 2px black;
	}
	h3 {
		color: white;
		font-size: 2.1em;
		line-height: normal;
		-webkit-text-stroke: 2px black;
		@media only screen and (max-width: 767.98px) {
			text-align: center;
			margin-top: 20px;
		}

	}
}
