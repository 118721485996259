@import '../../../assets/style/variables';
@import '../../../assets/style/animations';
.tokenomics {
	@media only screen and (max-width: 767.98px) {
		top: 50vh;
	}

	position: relative;
	width: 100%;
	height: 100vh;
	max-height: 1080px;
	max-width: 2100px;
	top: 10vh;
}
.content {
	@media only screen and (max-width: 767.98px) {
	flex-direction: column;
		background-size: 400%;
	}
	position: relative;
	height: 140%;
	max-width: 2100px;
	width: 100%;
	background-image: url(../../../assets/pictures/tokenomics_bg.svg);
	background-position: center 0%;
	background-size: contain;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
}
.info {
	@media only screen and (max-width: 767.98px) {
		top: 40%;
		align-items: center;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		h1{
			font-size: 150% !important;
			-webkit-text-stroke: 3px transparent !important;
		}
		h3{
			font-size: 2em !important;
		}
	}

	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 40px;
	max-width: 540px;
	top: 25%;
	left: 10%;
	transform: translateY(-25%);
	h1,
	h3 {
		color: $white;
		opacity: 0;
	}

	h1 {
		font-size: 6em;
		-webkit-text-stroke: 3px black;
	}
	h3 {
		font-size: 2em;
	}
}
.diagram {
	@media only screen and (max-width: 767.98px) {
		height: 25%;
		top: 12%;
		left: 50% !important;
		transform: translateX(-50%) !important;
	}
	position: absolute;
	height: 60%;
	width: auto;
	max-width: 700px;
	opacity: 0;
	top: 10%;
	right: 10%;
}
.stat {
	display: flex;
	justify-content: center;
	font-size: 2.5em;
	padding: 0 30%;
	padding-top: 2%;
	opacity: 0;
	color: white;
	border: 3px solid black;
	line-height: normal;
	text-wrap: nowrap;
	border-radius: 50px;
	width: 15vw;
}
.light-violet {
	background-color: $light-violet;
}
.light-green {
	background-color: $light-green;
}
