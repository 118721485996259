.logo {
	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		h1{
			font-size: 140% !important;
			-webkit-text-stroke: 2px transparent !important;
		}
	}
	gap: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	h1 {
		color: white;
		-webkit-text-stroke: 2px black;
		line-height: normal;
		padding-top: 5px;
		font-size: 4em;
	}
	img {
		height: auto;
		width: auto;
		max-height: 60px;
	}
}
