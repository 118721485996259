.carousel-container {
	position: absolute;
	left: 0;
	overflow: hidden;
	white-space: nowrap;
	z-index: 3;
	width: 100vw !important;
	box-sizing: border-box;
	background-color: #141414;
	padding: 10px;
}
.top {
	top: 0;
}
.bottom {
	@media only screen and (max-width: 767.98px) {
		bottom: -195vh;
	}

	bottom: -85vh;
}
.carousel-content {
	display: inline-block;
	white-space: nowrap;
	display: flex;
	align-items: center;
	font-size: 24px;
	font-weight: bold;
	color: white;
	animation: scroll 70s linear infinite;
}

@keyframes scroll {
	from {
		transform: translateX(-50%);
	}
	to {
		transform: translateX(0);
	}
}
