@import '../../../assets/style/variables.scss';
@import '../../../assets/style/animations.scss';

.rectangle {
	@media only screen and (max-width: 767.98px) {
		width: 100%;

	}

	position: relative;
	padding: 20px;
	color: white;
	width: 30vw;
	max-width: 600px;
	max-height: 400px;
	opacity: 0;
	height: 30vh;
	border-radius: 50px;
	border: 3px solid black;
	h1 {
		font-size: 5.5em;
		-webkit-text-stroke: 3px black;
	}
	p {
		font-size: 1.5em;
		line-height: normal;
		-webkit-text-stroke: 1.5px black;
	}
}
.yellow {
	@media only screen and (max-width: 767.98px) {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
	}

	position: absolute;
	left: 0;
	top: 24%;
	background-color: $yellow;
	box-shadow: 10px 10px 0 $dark-yellow;
}
.light-green {
	@media only screen and (max-width: 767.98px) {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
	}
	position: absolute;
	right: 0;
	top: 36%;
	background-color: $light-green;
	box-shadow: 10px 10px 0 $dark-green;
}
.light-violet {
	@media only screen and (max-width: 767.98px) {
		position: relative;
		top: 0;
		left: 0;
		right: 0;
	}
	position: absolute;
	bottom: 5%;
	left: 18%;
	background-color: $light-violet;
	box-shadow: 10px 10px 0 $dark-violet;
}
