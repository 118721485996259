.contract {
	background-color: white;
	width: 100%;
	border-radius: 8px;
	box-shadow: 5px 5px 0px 0px #000000;
	padding: 5px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media only screen and (max-width: 767.98px) {
		width: 90vw;
		flex-direction: column;
		p {
			font-size: 80% !important;
			width: 100% !important;
			text-align: center;
		}
		button {
			margin-top: 10px !important;
			padding: 20px 60px !important;
			width: 100%;
		}

	}
	@media only screen and (min-width: 768px) and (max-width: 1024px) {
	}
	p {
		padding-top: 1%;
		font-size: 1.8em;
		letter-spacing: 2px;
		width: fit-content;
		padding-right: 10px;
	}
	button {
		position: relative;
		background-color: black;
		border-radius: 16px;
		transition: all ease 0.5s;
		color: white;
		padding: 3px 55px;
		font-size: 1.5em;
		padding-top: 4px;
		height: 100%;
		cursor: pointer;
		border: none;
		outline: none;
		&:active {
			background-color: white;
			color: black;
		}
		p {
			position: absolute;
			line-height: normal;
			font-size: 100%;
			left: 60%;
			top: 50%;
			transform: translate(-60%, -50%);
		}
	}
}
