@import '../../../assets/style/variables.scss'; // Импортируем переменные

.button {
	transition: background-color 0.3s ease;
	border-radius: 50px;
	border: 3px solid black;
	color: white;
	padding: 0px 40px;
	max-height: 50px;
	cursor: pointer;
	text-wrap: nowrap;
	p {
		-webkit-text-stroke: 2px black;
		font-size: 26pt;
	}
	&.violet {
		background-color: $light-violet;
		box-shadow: 5px 5px 0px 0px $dark-violet;
		&:hover {
			background-color: darken($light-violet, 10%);
		}
	}

	&.yellow {
		background-color: $yellow;
		box-shadow: 5px 5px 0px 0px $dark-yellow;
		&:hover {
			background-color: darken($yellow, 10%);
		}
	}

	&.green {
		background-color: $light-green;
		box-shadow: 5px 5px 0px 0px $dark-green;
		&:hover {
			background-color: darken($light-green, 10%);
		}
	}
}
