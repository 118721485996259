// .green {
// 	background: linear-gradient(90deg, $light-green 0%, $dark-green 99.94%);

// 	&:hover {
// 		background: linear-gradient(
// 			90deg,
// 			darken($light-green, 10%) 0%,
// 			darken($dark-green, 10%) 99.94%
// 		);
// 	}
// }

.header {
	position: absolute;
	z-index: 1;
	top: 3%;
	@media only screen and (max-width: 767.98px) {
		top: 2%;
	}

	height: auto;
	width: 100%;

}
.header_content {
	margin: auto;
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media only screen and (max-width: 767.98px) {

		button{
			width: 50% !important;
			padding: 20px 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			p{
				font-size: 25pt !important;
				-webkit-text-stroke: 2px transparent;
			}
		}
	}
}
