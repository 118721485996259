@import '../../../assets/style/variables.scss';

.about_us {
	@media only screen and (max-width: 767.98px) {
		top: -10vh;
		h1{
			font-size: 2.5em !important;
		}
		h3{
			width: 90vw !important;
		}
		.about_picture{
			width: 90vw;
			box-shadow: 5px 5px 0 $dark-violet !important;
			height: auto !important;
		}
	}
	position: relative;
	top: 10vh;
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-height: 1080px;
	max-width: 2100px;

	h1 {
		margin-top: 50px;
		color: $light-violet;
		font-size: 4.5em;
	}
	h3 {
		color: white;
		font-size: 2.2em;
		text-align: center;
		width: 40%;
	}
	.about_picture {
		box-shadow: 20px 20px 0 $dark-violet;
		border-radius: 50px;
		z-index: 3;
		height: 100vh;
		max-height: 1050px;
	}
	.red {
		position: absolute;
		z-index: 2;
		height: 100vh;
		left: 0;
	}
	.green {
		position: absolute;
		z-index: 2;
		height: 100vh;
		right: 0;
	}
}
