@import '../../../assets/style/variables';
@import '../../../assets/style/animations';
.main {
	@media only screen and (max-width: 767.98px) {
		height: auto;
		padding-bottom: 15%;
		h1{
			font-size: 4rem !important;
			margin-top: 50%;
		}
		.big_bully{
			position: absolute !important;
			border: 2px solid white;
			border-radius: 50%;
			top: 36%;
		}
	}


	position: relative;
	background-color: $violet;
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	border-bottom-left-radius: 100px;
	border-bottom-right-radius: 100px;
	border-bottom: 5px solid black;
	box-shadow: 0px 0px 0px 20px $dark-violet;
	max-height: 1080px;

	h1 {
		color: white;
		font-size: 6em;
		line-height: normal;
		-webkit-text-stroke: 3px black;
	}
	h3 {
		color: white;
		width: 80%;
		line-height: normal;
		-webkit-text-stroke: 2px black;
		font-size: 2em;
	}
	.content {
		padding-top: 15%;
		display: flex;
		width: 90%;
		.big_bully {
			position: absolute;
			width: 38%;
			right: 5%;
			bottom: 0;
		}
	}
	.info {
		display: flex;
		flex-direction: column;
		gap: 30px;
		width: fit-content;
		max-width: 70%;
		height: auto;
	}
}
