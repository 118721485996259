@font-face {
	font-family: overdozesans;
	src: url(../fonts/overdozesans.ttf);
}
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: overdozesans;
	font-weight: var(--font-weight);
	line-height: 1.5em;
}
html,
body {
	width: 100%;
	min-height: 100%;
}
html {
	@media only screen and (max-width: 767.98px) {
		padding-bottom: 195vh;
	}

	display: flex;
	justify-content: center;
	background-image: url('../pictures/bg.svg');
	background-color: #141414;
	padding-bottom: 85vh;
	overflow-x: hidden;
}
body {
	@media only screen and (max-width: 767.98px) {
		width: 100vw;
		max-width: 768px;
	}
	position: relative;
	max-width: 2200px;
}
