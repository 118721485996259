.contacts {
	@media only screen and (max-width: 767.98px) {
		margin: auto;
	}

	display: flex;
	width: 85%;
	gap: 15px;
	align-items: center;
	margin-top: 10px;
}
.contacts img {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	width: 80%;
	height: auto;
	filter: drop-shadow(5px 3px 0px #000000);
}
